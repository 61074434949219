import { getExploreName } from '../utils/platform';

const host = 'educoach.online';

export const devConfig = {
	sdkKey: 'AW_w987kS_Gck8v1iLZHEw',
	sdkSecret: 'UX5fgHg7GNDFpOupNslC8uLC52P3m7clXRi3',
	webEndpoint: 'zoom.us',
	topic: 'testing alkurn',
	name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
	password: 'alkurn123',
	signature: '',
	sessionKey: '',
	userIdentity: '',
	// The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
	role: 1,
	hostUrl: `https://www.${host}`,
	apiUrl: `https://api.${host}`,
};





// Educoach: New ZOOM Video SDK keys
// SDK credentials
// SDK Key: AW_w987kS_Gck8v1iLZHEw
// SDK Secret: UX5fgHg7GNDFpOupNslC8uLC52P3m7clXRi3

// API keys
// API Key: e_wsSCakQSmKzobPqK0gDA
// API Secret: t80LvQMuQGpxmGE7wy3IPB5eiHhfNVla1Hk9