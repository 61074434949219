import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import AvatarActionContext from './context/avatar-context';
import Avatar from './components/avatar';
import VideoFooter from './components/video-footer';
import Pagination from './components/pagination';
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { useGalleryLayout } from './hooks/useGalleryLayout';
import { usePagination } from './hooks/usePagination';
import { useActiveVideo } from './hooks/useAvtiveVideo';
import { useAvatarAction } from './hooks/useAvatarAction';
import { useNetworkQuality } from './hooks/useNetworkQuality';
import ReportBtn from './components/report-btn';
import ShareView from './components/share-view';
import RemoteCameraControlPanel from './components/remote-camera-control';

import './video.scss';
import { devConfig } from '../../config/dev';

const VideoContainer: React.FunctionComponent<RouteComponentProps> = (props) => {
	const zmClient = useContext(ZoomContext);
	const {
		mediaStream,
		video: { decode: isVideoDecodeReady }
	} = useContext(ZoomMediaContext);
	const videoRef = useRef<HTMLCanvasElement | null>(null);
	const shareViewRef = useRef<{ selfShareRef: HTMLCanvasElement | HTMLVideoElement | null }>(null);
	const [isRecieveSharing, setIsRecieveSharing] = useState(false);
	const canvasDimension = useCanvasDimension(mediaStream, videoRef);
	const [meetingItem, setMeetingItem] = useState(null);
	const activeVideo = useActiveVideo(zmClient);
	const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
	const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
		zmClient,
		mediaStream,
		isVideoDecodeReady,
		videoRef,
		canvasDimension,
		{
			page,
			pageSize,
			totalPage,
			totalSize
		}
	);
	const avatarActionState = useAvatarAction(zmClient, visibleParticipants);
	const networkQuality = useNetworkQuality(zmClient);

	useEffect(() => {
		let meetingArgs: any = Object.fromEntries(new URLSearchParams(props.location.search));
		let { meeting_id, userIdentity, token, topic } = meetingArgs;
		//console.log('propsprops', meetingArgs);
		const checkMeeting = async () => {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${token}`);
			var requestOptions: any = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};
			const fetchResponse = await fetch(`${devConfig.apiUrl}/user-catalog/booking?order_id=${meeting_id}`, requestOptions);
			const response = await fetchResponse.json();
			try {
				let data = response.data;
				setMeetingItem(data);
				/* let user_id = parseInt(userIdentity);
				let meetingId = parseInt(meeting_id);
				let recipient_id = data?.recipients?.find((v: any) => v === user_id);

				if (data.id === meetingId && recipient_id) {
					console.log('user_id', user_id);
				} else {
					window.location.href = devConfig.hostUrl;
				}*/
			} catch (error) {
				console.error('error', error);
			}
		};
		checkMeeting();

	}, []);

	//console.log('props', props)
	//console.log('meetingItem', meetingItem);

	return (
		<div className="viewport">
			<ShareView ref={shareViewRef} onRecieveSharingChange={setIsRecieveSharing} />
			<div
				className={classnames('video-container', {
					'video-container-in-sharing': isRecieveSharing
				})}
			>
				<canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />
				<AvatarActionContext.Provider value={avatarActionState}>
					<ul className="avatar-list">
						{visibleParticipants.map((user, index) => {
							if (index > videoLayout.length - 1) {
								return null;
							}
							const dimension = videoLayout[index];
							const { width, height, x, y } = dimension;
							const { height: canvasHeight } = canvasDimension;
							return (
								<Avatar
									participant={user}
									key={user.userId}
									isActive={activeVideo === user.userId}
									networkQuality={networkQuality[`${user.userId}`]}
									style={{
										width: `${width}px`,
										height: `${height}px`,
										top: `${canvasHeight - y - height}px`,
										left: `${x}px`
									}}
								/>
							);
						})}
					</ul>
					<RemoteCameraControlPanel />
				</AvatarActionContext.Provider>
			</div>
			{ meetingItem && <VideoFooter className="video-operations" sharing selfShareCanvas={shareViewRef.current?.selfShareRef} meetingItem={meetingItem} location={props.location} />}

			{totalPage > 1 && <Pagination page={page} totalPage={totalPage} setPage={setPage} inSharing={isRecieveSharing} />}
			<ReportBtn />
		</div>
	);
};

export default VideoContainer;
